<template>
    <div class="salesContractMonthBox">
        <div class="top-echarts-two">
            <div class="echarts-left echarts-total">
                <div class="title-box">
                    <h1>合同数量</h1>
                    <!-- <span @click="lookMore(1)">查看完整数据</span> -->
                </div>
                <div style="width 100%;height:2.70rem;flex 4" id="contractQuantityId"></div>
            </div>
            <div class="echarts-right echarts-total">
                <div class="title-box">
                    <h1>预计销售方量</h1>
                    <!-- <span @click="lookMore(2)">查看完整数据</span> -->
                </div>
                <div style="width 100%;height:2.70rem;flex 4" id="estimatedSalesId"></div>
            </div>
        </div>
        <exportDom :dom-id="'.el-table__fixed-right'" :excel-name="'按销售区域月报'"></exportDom>
        <div class="table-list">
            <el-table
                id="salesTable"
                :data="tabListDataName"
                style="width: 100%"
                max-height="330"
                ref="tableRef"
                show-summary
                :summary-method="getSummaries"
            >
                <el-table-column
                    prop="name"
                    label="公司名称"
                    fixed
                    width="150"
                >
                </el-table-column>
                <el-table-column
                    v-for="(date, index) in tabListDataDate"
                    :key="index"
                    :label="date"
                >
                    <el-table-column
                        label="合同数量(个)"
                        :prop="date"
                        min-width="150"
                    >
                        <template slot-scope="scope">
                            <span v-for="(key, idx) in scope.row.list" :key="idx">
                                <template v-if="key.date === date">
                                    {{ key.htsl }}
                                </template>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="预计销售方量(方)"
                        :prop="date"
                        min-width="150"
                    >
                        <template slot-scope="scope">
                            <span v-for="(key, idx) in scope.row.list" :key="idx">
                                <template v-if="key.date === date">
                                    {{ key.yjxsfl }}
                                </template>
                            </span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    prop="htTotal"
                    label="数量小计(个)"
                    fixed="right"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="方量小计(方)"
                    fixed="right"
                    width="120"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import exportDom from './../export';
export default {
    components: { exportDom },
    data() {
        return {
            alertStatus: 0,
            tabListData: [],
            tabListDataName: [],
            clickName: '',
            tabListDataList: [],
            tabListDataDate: [],
            tabListEchartsHt: [],
            bbb: 0,
            echartsColor: ['#EA8343', '#3E79C4', '#E6E429', '#FA9E94'],
            echartsArrColor: [
                'rgba(234,130,67,0.3)',
                'rgba(62,120,195,0.3)',
                'rgba(19,189,99,0.3)',
                'rgba(118,224,168,0.3)',
            ],
        };
    },

    mounted() {
        this.$nextTick( () => {
            setTimeout(() => {
                // 原材料采购统计
                this.salescontractMonths();
                this.$refs.tableRef.doLayout();
            }, 500);
        });
    },
    methods: {
        // exportExcel() {
        //     /* 从表生成工作簿对象 */
        //     const wb = XLSX.utils.table_to_book(document.querySelector('.el-table__fixed-right'));
        //     /* 获取二进制字符串作为输出 */
        //     const wbout = XLSX.write(wb, {
        //         bookType: 'xlsx',
        //         bookSST: true,
        //         type: 'array',
        //     });
        //     try {
        //         FileSaver.saveAs(
        //         // Blob 对象表示一个不可变、原始数据的类文件对象。
        //         // Blob 表示的不一定是JavaScript原生格式的数据。
        //         // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
        //         // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
        //             new Blob([wbout], { type: 'application/octet-stream' }),
        //             // 设置导出文件名称
        //             '按销售区域月报.xlsx'
        //         );
        //     } catch (e) {
        //         if (typeof console !== 'undefined') {console.log(e, wbout);}
        //     }
        //     return wbout;
        // },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                // 获取与当前行时间相同的数据
                const arr = this.tabListDataList.filter(item => {
                    return column.property === item.date;
                });
                // 对应时间合同数量(个)和预计销售方量(方)的数值数组 // 对应每一列的数据数组
                // eslint-disable-next-line consistent-return
                let values = arr.map(item => {
                    if (column.label === '合同数量(个)') {return item.htsl;}
                    if (column.label === '预计销售方量(方)') {return item.yjxsfl;}
                });
                if (column.label === '合计(方)') {
                    // eslint-disable-next-line consistent-return
                    values = data.map(item => {
                        if (column.label === '合计(方)') {
                            return item.total;
                        }
                    });
                }
                if (column.label === '合计合同数量(个)') {
                    // eslint-disable-next-line consistent-return
                    values = data.map(item => {
                        if (column.label === '合计合同数量(个)') {
                            return item.htTotal;
                        }
                    });
                }
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return Math.round(prev + curr * 100);
                        }
                        return prev;
                    }, 0);
                    sums[index] = sums[index] / 100;
                } else {
                    sums[index] = ' ';
                }
            });
            return sums;
        },
        // 合同数量
        contractQuantityCh(data) {
            const myChart = this.$echarts.init(document.getElementById('contractQuantityId'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                    padding: [10, 0, 0, 0],
                },
                grid: {
                    top: '100',
                    left: '30',
                    right: '30',
                    bottom: '20',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        triggerEvent: true,
                        data: this.tabListDataDate,
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                            interval: 0,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(个)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                            padding: [10, 0, 0, 0],
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                fontSize: 10,
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(个)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function (params) {
                if (params.componentType === 'xAxis') {
                    that.clickName = params.value;
                    that.showAnalysis = true;
                } else {
                    that.clickName = params.name;
                    that.showAnalysis = true;
                }
            });
            myChart.setOption(option, true);
        },
        // 预计销售方量
        estimatedSalesCh(data) {
            const myChart = this.$echarts.init(document.getElementById('estimatedSalesId'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                legend: {
                    data: this.tabListEchartsHt,
                },
                grid: {
                    top: '100',
                    left: '30',
                    right: '30',
                    bottom: '20',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        triggerEvent: true,
                        data: this.tabListDataDate,
                        axisLabel: {
                            interval: 0,
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: '单位(方)',
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        nameTextStyle: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                series: data,
            };
            const that = this;
            myChart.on('click', function (params) {
                if (params.componentType === 'xAxis') {
                    that.clickName = params.value;
                    that.showAnalysis = true;
                } else {
                    that.clickName = params.name;
                    that.showAnalysis = true;
                }
            });
            myChart.setOption(option, true);
        },
        // 销售合同月报
        async salescontractMonths() {
            await this.$axios
                .get('/interfaceApi/report/tjfx/xsht/months/area?date=&datenum=12')
                .then(res => {
                    this.tabListData = res;
                    this.tabListDataList = res.list;
                    this.tabListDataDate = res.date;
                    // 表格渲染
                    this.tabListDataName = res.name.map(item => {
                        let yjxsflTotal = 0;
                        let htslTotal = 0;
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        arr.forEach(item => {
                            yjxsflTotal += Math.round(item.yjxsfl * 100) / 100;
                            htslTotal += Math.round(item.htsl * 100) / 100;
                        });
                        return {
                            name: item,
                            list: arr,
                            total: yjxsflTotal,
                            htTotal: htslTotal,
                        };
                    });
                    // 处理图表数据
                    this.tabListEchartsHt = res.name;
                    this.tabListEchartsHt.push('合计');
                    // 分组名称
                    const getSeriesData = res.name.map(item => {
                        const arr = res.list.filter(key => {
                            return key.name === item;
                        });
                        return arr;
                    });
                    // 合同数量
                    let htEchartSeriesData = [];
                    htEchartSeriesData = getSeriesData.map((item, index) => {
                        let htItemListData = [];
                        htItemListData = item.map(info => {
                            return info.htsl ? info.htsl : ',';
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        // const bgArrColor = this.echartsArrColor[index];
                        const htEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            data: htItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return htEchartSeries;
                    });
                    // 预计销售方量
                    let flEchartSeriesData = [];
                    flEchartSeriesData = getSeriesData.map((item, index) => {
                        let flItemListData = [];
                        flItemListData = item.map(info => {
                            return info.yjxsfl ? info.yjxsfl : ',';
                        });
                        const arrName = this.tabListEchartsHt[index];
                        const arrColor = this.echartsColor[index];
                        const flEchartSeries = {
                            name: arrName,
                            type: 'bar',
                            stack: '总量',
                            data: flItemListData,
                            showBackground: true,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'right',
                                color: arrColor,
                                fontSize: '14',
                                padding: [3, 0, 0, 10],
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return flEchartSeries;
                    });
                    // 合同数量合计
                    const htTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.htsl * 100) / 100;
                        });
                        return total;
                    });
                    const htTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: htTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    // 预计销售方量合计
                    const flTotalData = res.date.map(item => {
                        const arr = res.list.filter(key => {
                            return key.date === item;
                        });
                        let total = 0;
                        arr.forEach(a => {
                            total = Math.round(total * 100 + a.yjxsfl * 100) / 100;
                        });
                        return total;
                    });
                    const flTotal = {
                        name: '合计',
                        type: 'line',
                        yAxisIndex: 1,
                        data: flTotalData,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    };
                    const htCompleteData = htEchartSeriesData.slice(0, -1);
                    htCompleteData.push(htTotal);
                    const flCompleteData = flEchartSeriesData.slice(0, -1);
                    flCompleteData.push(flTotal);
                    // 合同数量图表
                    this.contractQuantityCh(htCompleteData);
                    // 预计销售方量图表
                    this.estimatedSalesCh(flCompleteData);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};

</script>
<style lang='stylus'>

</style>
